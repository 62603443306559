




import { Component, Vue } from 'vue-property-decorator'

@Component
export default class UrlCheck extends Vue {
  private async created() {
    await Vue.prototype.$http.httpWithToken
      .get(`/urlCheck`)
      .then((res: any) => {
        Vue.prototype.$logger.log(res)
      })
      .catch((error: any) => {
        Vue.prototype.$logger.log(error)
      })
  }
}
